import React, {useState, useEffect, useRef } from "react";
import { BodyPage } from "../../components/BodyPage";
import { BodySearch } from "../../components/BodySearch";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import CodeApi from "../../api/codes";
import { BODY_COUNT_PER_PAGE } from "../../config/constant";
import { Link, useNavigate } from "react-router-dom";

export const SellerList = ({ dataParam, nationParam, platformParam, searchTypeParam, searchTextParam, pageParam, onPageSubmit, onNationSubmit, onPlatformSubmit, onSearchTextSubmit }) => {
  const datas = dataParam ? dataParam.results : null;
  const pageInfo = dataParam ? {"totalCount": dataParam.count, "currPage": pageParam, "prevPage": dataParam.previous, "nextPage": dataParam.next} : null;

  const [initialCodeLoaded, setInitialCodeLoaded] = useState(false);

  // 검색 옵션
  const searchsDict = [
    { value: '', label: '전체' },
    { value: 'seller', label: '판매상' },
  ];

  // 검색 타입
  const [searchType, setSearchType] = useState(searchTypeParam);
  const handleSearchType = (e) => {
    let value = e.target.value;
    setSearchType(value);
    onSearchTextSubmit(value, searchText);
  };

  // 검색어
  const [searchText, setSearchText] = useState(searchTextParam);
  const handleSearchTextChange = (e) => {
    let value = e.target.value;
    setSearchText(value);
  };
  const handleKeyDown = (e) => {
    if(e.key === "Enter") {
      onSearchTextSubmit(searchType, searchText);
    }
  } 

  // 공통코드 구하기기
  const PullComCode = (category='', key='', depth = '0') => {
    const formData = { category: category, key: key, depth: depth }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        if (response.data.category === "continent") {
          if (response.data.depth === '0') {
            // setContinent(continentParam);
            // setContinents(response.data.list);
            PullComCode('continent', '', 1);
          }
          else if (response.data.depth === '1') {
            setNation(nationParam);
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('continent', nationParam, 2);
          }
          else if (response.data.depth === '2') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
            setInitialCodeLoaded(true);
          }
        }
        else if (response.data.category === "nation") {
          if (response.data.depth === '0') {
            setNations(response.data.list);
            setNationsDict(response.data.dict);
            PullComCode('platform', '', 0);
          }
          else if (response.data.depth === '1') {
            setPlatform(platformParam);
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
            setInitialCodeLoaded(true);
          }
        }
        else if (response.data.category === "platform") {
          if (response.data.depth === '0') {
            setPlatforms(response.data.list);
            setPlatformsDict(response.data.dict);
            setInitialCodeLoaded(true);
          }
        }
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 국가
  const [nation, setNation] = useState(nationParam);
  const [nations, setNations] = useState([])
  const [nationsDict, setNationsDict] = useState({})
  const handleNation = (e) => {
    let value = e.target.value;
    setNation(value);
    if (value === '') {
      PullComCode('continent', '', '1');
    } else {
      PullComCode('nation', value, 1);
    }
    onNationSubmit(value, '');
  };

  // 플랫폼
  const [platform, setPlatform] = useState(platformParam);
  const [platforms, setPlatforms] = useState([])
  const [platformsDict, setPlatformsDict] = useState({})
  const handlePlatform = (e) => {
    let value = e.target.value;
    setPlatform(value);
    onPlatformSubmit(value);
  };

  const handleSellerHome = (e, url) => {
    e.preventDefault();
    window.open(url, '_blank');
  }

  // 상세 페이지 이동
  const handleDetailSubmit = (sellerParam) => {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
    navigate(`/merchants/${sellerParam}`);
  }

  function SequenceNumber(currIndex) {
    return (pageParam-1) * BODY_COUNT_PER_PAGE + currIndex;
  }

  // 처음 한번 실행
  useEffect(() => {
    // 국가/플랫폼 코드
    PullComCode('continent', '', '1');  // level(0: continent, 1: nation,2: platform)

  //   // 브랜드 코드가 API로부터 가져와진 경우 스톱
  //   let timerId = setTimeout(function tick() {
  //     if (initialCodeLoaded === true) {
  //       clearTimeout(timerId)
  //       setIinitialCodeLoaded()
  //       onPageSubmit(0);        
  //     }
  //     else
  //     {
  //       timerId = setTimeout(tick, 100); // (*)
  //       console.log('timer repreated!!!');
  //     }
  //   }, 100);
  //   return () => clearTimeout(timerId);
  }, []);


  return (
    <div className="seller-list">
      <div className="div-2">
        <div className="bulletin-body">
          <div className="body">
            <div className="bulletin-body-title">
              <div className="title">판매자별현황</div>
            </div>

            <div className="body-head">
              <div className="nation">
                <div className="dropdown">
                  <div className="text-wrapper-8">국가</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-9">저작권</div> */}
                  <select className="text-wrapper-9" style={{width:"90px", height:"25px", border:"none"}} id="nation" name="nation" value={nation} onChange={(e) => handleNation(e)}>
                    { nations.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="platform">
                <div className="dropdown-3">
                  <div className="text-wrapper-8">플랫폼</div>
                </div>

                <div className="dropdown-2">
                  {/* <div className="text-wrapper-9">저작권</div> */}
                  <select className="text-wrapper-9" style={{width:"80px", height:"25px", border:"none"}} id="platform" name="platform" value={platform} onChange={(e) => handlePlatform(e)}>
                    { platforms.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>
              </div>

              <div className="search">
                <div className="dropdown-4">
                  {/* <div className="text-wrapper-9">판매상</div> */}
                  <select className="text-wrapper-9" style={{width:"80px", height:"25px", border:"none"}} id="searchType" name="searchType" value={searchType} onChange={(e) => handleSearchType(e)}>
                    { searchsDict.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>                  

                </div>

                <div className="dropdown-2">
                  {/* <div className="element">저작권</div> */}
                  <input className="element" type="text" name="searchText" value={searchText} onChange={handleSearchTextChange} onKeyDown={(e) => handleKeyDown(e)} placeholder="검색" style={{width:"80px", height:"25px", border:"none"}}/>
                  
                </div>
              </div>
            </div>

            <div className="body-table">
              <div className="form-table">

                <div className="head-frame">
                  <div className="body-row-cell">
                    <div className="text-wrapper-10">번호</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-10">국가</div>
                  </div>

                  <div className="body-row-cell-2">
                    <div className="text-wrapper-10">플랫폼</div>
                  </div>

                  <div className="body-row-cell-3">
                    <div className="text-wrapper-10">판매자</div>
                  </div>

                  <div className="body-row-cell-4">
                    <div className="text-wrapper-11">판매자홈</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-10">생성일</div>
                  </div>

                  <div className="body-row-cell-5">
                    <div className="text-wrapper-10">상태</div>
                  </div>
                </div>


                {datas ? datas.map((prd, index, total) => (
                <Link className="record-frame" to={`/merchants/${prd.seller_id}`} key={prd.id} style={{cursor:"pointer"}}>

                  <div className="body-row-data">
                    <div className="text-wrapper-12">{SequenceNumber(index+1)}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-12">{nationsDict[prd.nation]}</div>
                  </div>

                  <div className="body-row-data-2">
                    <div className="text-wrapper-12">{platformsDict[prd.platform]}</div>
                  </div>

                  <div className="body-row-data-3">
                    <div className="text-wrapper-12">{prd.seller_id}</div>
                  </div>

                  <div className="body-row-data-4">
                    <div className="text-wrapper-12">{prd.seller_home}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-12">{prd.created_at.substring(0,10)}</div>
                  </div>

                  <div className="body-row-data-5">
                    <div className="text-wrapper-12">{prd.status ? prd.status : '활성' }</div>
                  </div>

                </Link>
                )) : <></>}


              </div>
            </div>

            {/* <BodyPage className="body-page-instance" /> */}
            {pageInfo ?
              <BodyPage className="body-page-instance" pageInfo={pageInfo} onPageSubmit={onPageSubmit} />
            :<></>}


          </div>
        </div>

        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="left-menu-instance" />
      </div>
    </div>
  );
};
