import React, { useState, useRef, useContext, useEffect } from "react";
import { MainLogo } from "../MainLogo";
import { RightMenu } from "../RightMenu";
import { LeftMenu } from "../LeftMenu";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import DeterminedApi from "../../api/determineds";
import CollectApi from "../../api_elastic/collects";
import useForm from '../hooks/useForm';
import { infringeTypes, infringeTypeOptions } from "../../config/codes";

export const CollectDetail = ({dtm}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setCollectDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);

  const [ thumbClickedUrl, setThumbClickedUrl ] = useState(dtm._source.thumb1);
  const [error, setError] = useState("");
  const [{infringe, content}, onChange, onReset] = useForm({
    'infringe': '',
    'content': ''
  });


  const [selectedInfringeOption, setSelectedInfringeOption] = useState(infringeTypeOptions[1].value);
  const handleSelectInfringeChange = (event) => {
    let value = event.target.value;
    setSelectedInfringeOption(value);
  };

  const refThumbMain = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      if (index < 10)
        data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }


  const onDelete = (_id, e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("mall", dtm._source.mall);
    formData.append("determin_code", dtm._source.determin_code);
    formData.append("determin_name", dtm._source.determin_name);
    formData.append("infringe", "confirmed");

    // 이 수집품은 삭제되었음을 서버에 전송
    DeterminedApi.InsertConfirmed(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        // 엘라스틱에서 이 수집품 데이터 삭제
        const params = { query : { term : { _id : { value : `${_id}` } } } }
        CollectApi.DeleteCollect(params).then(response => {
          if(response.status == 200) {
            alert(`수집품 데이터가 삭제되었습니다.`);
            navigate('/collects');
          } else {
            alert(response.data.msg)
          }
        }).catch(error => {
          console.log(error.message);
          alert(error.message)
        })
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }  

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    // if (determinDesc.length <= 0) {
    //   const result = alert("침해된 이미지를 선택해 주세요!");
    //   return false;
    // }
    if (selectedInfringeOption.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("침해 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function InsertDeterminedData(formData) {
    DeterminedApi.InsertDetermined(formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postCollectedByClicked(data_list) {
    let formData = new FormData();
    formData.append("mall", dtm._source.mall);
    formData.append("category", dtm._source.category);
    formData.append("brand", dtm._source.brand);
    formData.append("model", dtm._source.model);
    formData.append("product_name", dtm._source.product_name);
    formData.append("determin_code", dtm._source.determin_code);
    formData.append("determin_name", dtm._source.determin_name);
    formData.append("product_price", dtm._source.product_price);
    formData.append("price_floor", dtm._source.price_floor);
    formData.append("determin_price", dtm._source.determin_price);
    formData.append("url", dtm._source.url.substring(0,250));
    formData.append("similarity", dtm._source.title_similarity ? dtm._source.title_similarity.toFixed(2) : 0.0);
    formData.append("infringe", selectedInfringeOption);
    formData.append("content", content);
    formData.append("product", isNumeric(dtm._source.product_id) ? parseInt(dtm._source.product_id) : 0);
    formData.append("seller", dtm._source.seller);
    formData.append("seller_home", dtm._source.seller_home);
    formData.append("sold_volume", dtm._source.sold_out);

    formData.append("determins", JSON.stringify(data_list));
    InsertDeterminedData(formData);
    // 1안
    // let downloadedCount = 0;
    // data_list.map( async (data, index, total) => {
    //   try { //, headers: {"Access-Control-Allow-Origin": "*",}
    //     const response = await fetch(data.determin_image);
    //     if (!response.ok) {
    //       throw new Error(`HTTP error! status: ${response.status}`);
    //     }
    //     const blob = response.blob();
    //     downloadedCount += 1;
    //     let now = (new Date()).getTime()+index;
    //     let determin_file = 'determin_' + now + '.' + data.determin_image.substring(data.determin_image.lastIndexOf('.') + 1);
    //     formData.append(`determin_image${index+1}`, new File([blob], determin_file));
    //     data.determin_image = determin_file;
    //     formData.append("determins", JSON.stringify(data_list));
    //     if (downloadedCount === data_list.length) {
    //       InsertDeterminedData(formData);
    //     }
    //   } catch (error) {
    //     console.error('이미지 다운로드 실패:', error);
    //   }
    // });

    // 2안
    // const link = document.createElement('a');
    // link.href = data.determin_image;
    // link.download = determin_file;
    // document.body.appendChild(link);
    // link.click();
    // document.body.removeChild(link);

  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      let data_list = [];
      dtm._source.product_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      dtm._source.official_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      dtm._source.determined_similar.map((sim, index, total) => {
        if (sim.checked === true) {
          let data_dict = { 
            product_image: sim.product.substring(sim.product.lastIndexOf('media/')+6),
            determin_image: sim.determin, 
            src_name: sim.src_name,
            src_desc: sim.src_desc,
            similarity: sim.similarity,
          };
          if (data_list.length < 5)
            data_list.push(data_dict);
        }
      })
      // if (data_list.length > 0) {
      postCollectedByClicked(data_list);
      // }
    }  
  }

  const onImageHeartCheck = (param_sim, e) => {
    // e.preventDefault();
    let desc = '';
    dtm._source.product_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    dtm._source.official_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    dtm._source.determined_similar.map((sim, index, total) => {
      if (sim === param_sim) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    // 화면상에 침해 내용 출력
    setCollectDesc(desc);
  }

  const onContentChange = e => {
    const { value, name } = e.target; // 우선 e.target 에서 name 과 value 를 추출
    setFormData({
      ...formData,  // 기존의 input 객체를 복사한 뒤
      [name]: value // name 키를 가진 값을 value 로 설정
    });
    console.log(value);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }

  function displayLocalDate(utc_dt) {
    let local_dt = moment(utc_dt, 'YYYY-MM-DDTHH:mm:ss');
    local_dt = local_dt.add(9, 'hours');
    return local_dt.format('YYYY-MM-DD');
  }

  return (
    <div className="collect-detail">
      <div className="div-6">
        <div className="main-frame">
          <div className="title-wrapper">
            <div className="title-3">수집품 상세</div>
          </div>

          <div className="body-sub-title-2" />

          <div className="section-frame">
            <div className="frame-2">
              <div className="div-image-wrapper">
                {/* <img className="div-image" alt="Div image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a161ac9f83776dc7b8c93/img/div-image-2-2.png" /> */}
                <div className="div-image" style={{cursor:'pointer', backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} ref={refThumbMain} name="test.png"/>
              </div>

              <div className="frame-3">
                {/* <div className="frame-4" />
                <div className="frame-5" />
                <div className="frame-6" />
                <div className="frame-7" />
                <div className="frame-8" /> */}
                { 
                [dtm._source.thumb1, dtm._source.thumb2, dtm._source.thumb3, dtm._source.thumb4, dtm._source.thumb5].map((thumburl, index, total) => (
                  thumburl && thumburl.length > 0 ?
                  <div className={`frame-${index+4}`} style={{cursor:'pointer', border:'1px solid', backgroundImage: `url(${thumburl})`, backgroundSize:'contain'}} onClick={(e)=>{onThumbClick(thumburl, e)}} key={index} />
                  : null
                ))
              }

              </div>
            </div>

            <div className="frame-9">
              <div className="frame-10">
                <div className="text-wrapper-19">브랜드 : {dtm._source.brand}</div>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">쇼핑몰 : {dtm._source.mall} / {dtm._source.category}</div>
              </div>

              <div className="frame-11">
                <p className="p">
                {dtm._source.determin_name.substring(0,100)}
                </p>
              </div>

              <div className="frame-10">
                <a className="text-wrapper-19">판매가 : {dtm._source.determin_price.toLocaleString('ko-KR') }({dtm._source.currency})</a>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">정품하한 : {dtm._source.price_floor.toLocaleString('ko-KR')}</div>
              </div>


              <div className="frame-10">
                <a className="text-wrapper-19" href={dtm._source.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {dtm._source.seller} Home</a>
              </div>

              <div className="frame-10">
                <a className="text-wrapper-19" href={dtm._source.url} target="blank" style={{"color": "blue"}}>쇼핑몰 수집품 상세보기</a>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">수집형태 : {dtm._source.search_type}</div>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">이미지유사도 : {image_similarity_string(dtm._source.product_similar)}</div>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">공홈유사도 : {image_similarity_string(dtm._source.official_similar)}</div>
              </div>

              <div className="frame-10">
                <div className="text-wrapper-19">판별품유사도 : {image_similarity_string(dtm._source.determined_similar)}</div>
              </div>

            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-12">
              <div className="text-wrapper-20">타이틀유사도</div>
            </div>

            <div className="frame-13">
              <div className="frame-14">
                <div className="text-wrapper-21">정품 제목</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">{dtm._source.product_name}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">위조품 제목</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">{dtm._source.determin_name.substring(0,50)}</div>
              </div>
            </div>

            <div className="frame-13">
              <div className="frame-16">
                <div className="text-wrapper-21">브랜드/모델</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">{dtm._source.brand} / {dtm._source.model}</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">타이틀 유사도</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">{dtm._source.title_similarity ? dtm._source.title_similarity.toFixed(2) : null}</div>
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-18">
              <div className="text-wrapper-20">가격 유사도({dtm._source.currency})</div>
            </div>

            <div className="frame-13">
              <div className="frame-14">
                <div className="text-wrapper-21">정품 기준가</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">{dtm._source.product_price ? dtm._source.product_price.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">위조품 판매가</div>
              </div>

              <div className="frame-15">
                <div className="text-wrapper-21">{dtm._source.determin_price ? dtm._source.determin_price.toLocaleString('ko-KR') : null}</div>
              </div>
            </div>

            <div className="frame-13">
              <div className="frame-16">
                <div className="text-wrapper-21">정품 하한가</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">{dtm._source.price_floor ? dtm._source.price_floor.toLocaleString('ko-KR') : null}</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">정품 하한가 기준</div>
              </div>

              <div className="frame-17">
                <div className="text-wrapper-21">{(dtm._source.determin_price - dtm._source.price_floor).toLocaleString('ko-KR')}</div>
              </div>
            </div>
          </div>

          {/* // 정품 이미지 유사도 */}
          <div className="section-frame-3">
            <div className="frame-10">
              <div className="text-wrapper-20">정품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-4">

            { dtm._source.product_similar && dtm._source.product_similar.map((sim, index, total) => (
            <div className="rec-item-frame">
              <div className="div-7">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image-2" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} /> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                    </div>
                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>

              <div className="div-7">
                <div className="thumbnail-2">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">similarity</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>
            </div>
            ))}
          </div>

          {/* // 공홈 이미지 유사도 */}
          <div className="section-frame-3">
            <div className="frame-10">
              <div className="text-wrapper-20">공홈 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-4">

            { dtm._source.official_similar && dtm._source.official_similar.map((sim, index, total) => (
            <div className="rec-item-frame">
              <div className="div-7">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} /> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />
                    </div>

                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>

              <div className="div-7">
                <div className="thumbnail-2">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />
                    </div>

                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">similarity</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>
            </div>
            ))}
          </div>

          {/* // 기판별품 이미지 유사도 */}
          <div className="section-frame-3">
            <div className="frame-10">
              <div className="text-wrapper-20">기판별품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-4">

            { dtm._source.determined_similar && dtm._source.determined_similar.map((sim, index, total) => (
            <div className="rec-item-frame">
              <div className="div-7">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain'}} /> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.product})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />
                    </div>

                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">{sim.src_name}</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.src_desc}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-16">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>

              <div className="div-7">
                <div className="thumbnail-2">
                  <div className="overlap-group-2">
                    <div className="back-image-frame">
                      {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/678a14b5645cc1fc8fa198e4/img/backimage.png" /> */}
                      {/* <img className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain'}}/> */}
                      <div className="back-image" style={{backgroundImage: `url(${sim.determin})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />
                    </div>

                    {/* <button className="button-3">
                      <div className="button-box-2"></div>
                    </button> */}
                    <button className="button-3" style={{cursor:'pointer'}} onClick={(e)=>{onImageHeartCheck(sim, e)}}>
                      <div className="button-box-2"><FaHeart size="24px" fill={sim.checked > 0 ? "tomato" : "gray"}/></div>
                    </button>

                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">similarity</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">{sim.similarity ? sim.similarity.toFixed(2) : null}</div>
                    </div>
                  </div>

                  <div className="frame-19">
                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>

                    <div className="frame-17">
                      <div className="text-wrapper-21">&nbsp;</div>
                    </div>
                  </div>

                  <div className="frame-20" />
                </div>
              </div>
            </div>
            ))}
          </div>



          <form className="section-frame-5" name="frm" onSubmit={onSubmit} encType="multipart/form-data">
            <div className="form-title-frame">
              <div className="form-title">판별하기</div>
            </div>

            <div className="form-caption-frame">
              <div className="form-caption-wrapper">
                <div className="form-caption">판별 : {determinDesc}</div>
              </div>

              <div className="form-determ-tag-wrapper">
                <div className="form-determ-tag">침해 사유</div>
              </div>

              <div className="form-determ-drop-wrapper">
                {/* <div className="form-determ-drop">판별 사유</div> */}
                <select className="form-determ-drop" id="infringe" name="infringe" value={selectedInfringeOption} onChange={handleSelectInfringeChange} style={{border:"none"}}>
                  { infringeTypeOptions.filter((option, index, total) => index > 0).map((option, index, total) => ( 
                    <option value={option.value}>{option.label}</option>
                  ))}
                </select>

              </div>
            </div>

            <div className="form-text-frame">
              <div className="form-text-wrapper">
                {/* <div className="form-text">내용을 입력해 주세요</div> */}
                <textarea className="form-text" name="content" value={content} onChange={onChange} style={{border:"none"}}></textarea>

              </div>
            </div>

            <div className="form-submit-frame">
              <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={(e)=>{onDelete(dtm._id, e)}}>
                <div className="form-submit">삭&nbsp;&nbsp;제</div>
              </div>

              <div className="form-submit-wrapper" style={{cursor:'pointer'}} onClick={onSubmit}>
                <div className="form-submit-2">저&nbsp;&nbsp;장</div>
              </div>
            </div>
          </form>

        </div>

        <MainLogo className="main-logo-2" />
        <RightMenu className="right-menu-3" />
        <LeftMenu className="left-menu-3" />
      </div>
    </div>
  );
};
