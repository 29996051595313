import * as merchantApi from "../api_elastic/merchants";

import {
  reducerUtils,
  handleAsyncActionsList,
  handleAsyncActionsDetail,
  createPromiseSagaList,
  createPromiseSagaDetail,
} from "../lib/asyncUtils";
import { takeEvery, getContext, takeLatest } from "redux-saga/effects";

/* 액션 타입 */

// 상품목록 조회하기
const GET_MERCHANTLIST = "GET_MERCHANTLIST";
const GET_MERCHANTLIST_SUCCESS = "GET_MERCHANTLIST_SUCCESS";
const GET_MERCHANTLIST_ERROR = "GET_MERCHANTLIST_ERROR";

// 상품 항목 조회하기
const GET_MERCHANTDETAIL = "GET_MERCHANTDETAIL";
const GET_MERCHANTDETAIL_SUCCESS = "GET_MERCHANTDETAIL_SUCCESS";
const GET_MERCHANTDETAIL_ERROR = "GET_MERCHANTDETAIL_ERROR";

const CLEAR_MERCHANTTLIST = "CLEAR_MERCHANTTLIST";

export const getMerchantList = (data) => ({
  type: GET_MERCHANTLIST,
  payload: { data: data },
  meta: data.size,
});

export const getMerchantDetail = (data) => ({
  type: GET_MERCHANTDETAIL,
  payload: { data: data },
  meta: data.query.term._id.value,
});

const getMerchantListSaga = createPromiseSagaList(
  GET_MERCHANTLIST,
  merchantApi.getMerchantList
);

const getMerchantDetailSaga = createPromiseSagaDetail(
  GET_MERCHANTDETAIL,
  merchantApi.getMerchantDetail
);

// 사가들을 합치기
export function* merchantSaga() {
  yield takeEvery(GET_MERCHANTLIST, getMerchantListSaga);
  yield takeEvery(GET_MERCHANTDETAIL, getMerchantDetailSaga);
}

// initialState 쪽도 반복되는 코드를 initial() 함수를 사용해서 리팩토링 했습니다.
const initialState = {
  merchantlist: reducerUtils.initial(),
  merchantdetail: reducerUtils.initial(),
};

export default function merchantReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MERCHANTLIST:
    case GET_MERCHANTLIST_SUCCESS:
    case GET_MERCHANTLIST_ERROR:
      const reducerList = handleAsyncActionsList(
        GET_MERCHANTLIST,
        "merchantlist",
        true
      );
      return reducerList(state, action);

    case GET_MERCHANTDETAIL:
    case GET_MERCHANTDETAIL_SUCCESS:
    case GET_MERCHANTDETAIL_ERROR:
      const reducerDetail = handleAsyncActionsDetail(
        GET_MERCHANTDETAIL,
        "merchantdetail",
        true
      );
      return reducerDetail(state, action);

    case CLEAR_MERCHANTTLIST:
      return {
        ...state,
        merchantlist: reducerUtils.initial(),
      };

    default:
      return state;
  }
}
