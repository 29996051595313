import React, { useState, useRef, useEffect } from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";
import CodeApi from "../../api/codes";
import ProductApi from "../../api/products";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";

import { actives, activeOptions } from "../../config/codes";
import { categories, categoryOptions } from "../../config/codes";
import { currencyUnits, currencyUnitOptions } from "../../config/codes";

export const ProductDetail = ({prd}) => {
  const navigate = useNavigate();

  // 브랜드
  const brandRef = useRef();
  const [brand, setBrand] = useState(prd.brand);
  const handleBrandChange = (e) => {
    let value = e.target.value;
    setBrand(value);
  };

  // 모델
  const modelRef = useRef();
  const [model, setModel] = useState(prd.model);
  const handleModelChange = (e) => {
    let value = e.target.value;
    setModel(value);
  };

  // 카테고리
  const categoryRef = useRef(prd.category);
  const [category, setCategory] = useState(prd.category);
  const [categorys, setCategorys] = useState([])
  const handleCategoryChange = (e) => {
    let value = e.target.value;
    setCategory(value);
  };

  // 상품제목
  const productNameRef = useRef();
  const [productName, setProductName] = useState(prd.product_name);
  const handleProductNameChange = (e) => {
    let value = e.target.value;
    setProductName(value);
  };

  // 키워드
  const keywordsRef = useRef();
  const [keywords, setKeywords] = useState(prd.keywords);
  const handleKeywordsChange = (e) => {
    let value = e.target.value;
    setKeywords(value);
  };

  // 화폐단위
  const currencyRef = useRef(prd.currency);
  const [currency, setCurrency] = useState(prd.currency);
  const [currencys, setCurrencys] = useState([])
  const handleCurrencyChange = (e) => {
    let value = e.target.value;
    setCurrency(value);
  };

  // 기준가격
  const firstPriceRef = useRef();
  const [firstPrice, setFirstPrice] = useState(prd.first_price);
  const handleFirstPriceChange = (e) => {
    let value = e.target.value;
    setFirstPrice(value);
  };

  // 최종가격
  const finalPriceRef = useRef();
  const [finalPrice, setFinalPrice] = useState(prd.final_price);
  const handleFinalPriceChange = (e) => {
    let value = e.target.value;
    setFinalPrice(value);
  };

  // 정품하한
  const priceFloorRef = useRef();
  const [priceFloor, setPriceFloor] = useState(prd.price_floor);
  const handlePriceFloorChange = (e) => {
    let value = e.target.value;
    setPriceFloor(value);
  };

  // 상품 내용
  const contentRef = useRef();
  const [content, setContent] = useState(prd.content);
  const handleContentChange = (e) => {
    let value = e.target.value;
    setContent(value);
  };

  // Input Click
  const handleFileSelectClick = (e, ref) => {
    ref.current.click();
  }

  // 썸네일01
  const refThumb1 = useRef(null);
  const [fileThumb1, setFileThumb1] = useState(null);
  const [thumb1, setThumb1] = useState(prd.thumb1);
  const handleThumb1Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileThumb1(file);
      setThumb1(file.name);
    }
  };


  // 썸네일02
  const refThumb2 = useRef(null);
  const [fileThumb2, setFileThumb2] = useState(null);
  const [thumb2, setThumb2] = useState(prd.thumb2);
  const handleThumb2Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileThumb2(file);
      setThumb2(file.name);
    }
  };

  // 썸네일03
  const refThumb3 = useRef(null);
  const [fileThumb3, setFileThumb3] = useState(null);
  const [thumb3, setThumb3] = useState(prd.thumb3);
  const handleThumb3Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileThumb3(file);
      setThumb3(file.name);
    }
  };

  // 썸네일04
  const refThumb4 = useRef(null);
  const [fileThumb4, setFileThumb4] = useState(null);
  const [thumb4, setThumb4] = useState(prd.thumb4);
  const handleThumb4Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileThumb4(file);
      setThumb4(file.name);
    }
  };


  // 썸네일05
  const refThumb5 = useRef(null);
  const [fileThumb5, setFileThumb5] = useState(null);
  const [thumb5, setThumb5] = useState(prd.thumb5);
  const handleThumb5Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileThumb5(file);
      setThumb5(file.name);
    }
  };


  // 바디이미지01
  const refImage1 = useRef(null);
  const [fileImage1, setFileImage1] = useState(null);
  const [image1, setImage1] = useState(prd.image1);
  const handleImage1Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileImage1(file);
      setImage1(file.name);
    }
  };

  // 바디이미지02
  const refImage2 = useRef(null);
  const [fileImage2, setFileImage2] = useState(null);
  const [image2, setImage2] = useState(prd.image2);
  const handleImage2Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileImage2(file);
      setImage2(file.name);
    }
  };


  // 바디이미지03
  const refImage3 = useRef(null);
  const [fileImage3, setFileImage3] = useState(null);
  const [image3, setImage3] = useState(prd.image3);
  const handleImage3Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileImage3(file);
      setImage3(file.name);
    }
  };


  // 바디이미지04
  const refImage4 = useRef(null);
  const [fileImage4, setFileImage4] = useState(null);
  const [image4, setImage4] = useState(prd.image4);
  const handleImage4Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileImage4(file);
      setImage4(file.name);
    }
  };


  // 바디이미지05
  const refImage5 = useRef(null);
  const [fileImage5, setFileImage5] = useState(null);
  const [image5, setImage5] = useState(prd.image5);
  const handleImage5Change = (e) => {
    let file = e.target.files?.[0];
    if (file) {
      setFileImage5(file);
      setImage5(file.name);
    }
  };


  // 거래처
  const customerIdRef = useRef();
  const [customerId, setCustomerId] = useState(prd.customer_id);
  const handleCustomerIdChange = (e) => {
    let value = e.target.value;
    setCustomer(value);
  };

  const onDelete = (e) => {
    e.preventDefault();

    // 지재권 데이터 재거 서버에 전송
    ProductApi.DeleteProduct(id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`상품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })    
  }

  // validate Input Columns
  const validateInputs = () => {
    if (brand.length <= 0) {
      const result = alert("브랜드명을 입력해 주세요!");
      return false;
    }
    else if (model.length <= 0) {
      const result = alert("모델명을 입력해 주세요!");
      return false;
    }
    else if (category.length <= 0) {
      const result = alert("카테고리를 입력해 주세요!");
      return false;
    }
    return true;
  }

  // 이전 페이지로
  const handleUpdate = (e) => {
    e.preventDefault();
    if (validateInputs() === true) {
      let formData = new FormData();
      formData.append("brand", brand);
      formData.append("model", model);
      formData.append("category", category);
      formData.append("product_name", productName);
      formData.append("keywords", keywords);
      formData.append("currency", currency);
      formData.append("first_price", firstPrice);
      formData.append("final_price", finalPrice);
      formData.append("price_floor", priceFloor);
      formData.append("content", content);
      if (fileThumb1) 
        formData.append("thumb1", fileThumb1);
      if (fileThumb2) 
        formData.append("thumb2", fileThumb2);
      if (fileThumb3) 
        formData.append("thumb3", fileThumb3);
      if (fileThumb4) 
        formData.append("thumb4", fileThumb4);
      if (fileThumb5) 
        formData.append("thumb5", fileThumb5);
      if (fileImage1) 
        formData.append("thumb1", fileImage1);
      if (fileImage2) 
        formData.append("thumb2", fileImage2);
      if (fileImage3) 
        formData.append("thumb3", fileImage3);
      if (fileImage4) 
        formData.append("thumb4", fileImage4);
      if (fileImage5) 
        formData.append("thumb5", fileImage5);

      formData.append("customer_id", customerId);
      formData.append("active", active);

      // formData.append(`determin_image${index+1}`, new File([blob], determin_file));      
  
      ProductApi.UpdateProduct(prd.id, formData).then(response => {
        if(response.status == 200 || response.status == 201) {
          alert("정상적으로 저장되었습니다.")
        } else {
          alert(response.statusText)
        }
      }).catch(error => {
        console.log(error.message);
        alert(error.message)
      })    
    }
  }


  // 이전 페이지로
  const handleBackward = (e) => {
    e.preventDefault();
    navigate(-1);
  }

  const [active, setActive] = useState(prd.active);
  const handleSelectActiveChange = (e) => {
    let value = e.target.value;
    setActive(value);
  };


  useEffect(() => {
    // // 카테고리
    // PullCategoryCode('mall_category', '', '0', '');

    // // 화폐단위
    // PullCurrencyCode('currency_unit', '', '0', '');

  },[]);

  // 카테고리 구하기
  const PullCategoryCode = (category='', key='', depth = '0', include='') => {
    const formData = { category: category, key: key, depth: depth, include: include }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setCategory(value);
        setCategorys(response.data.list);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  // 화폐단위 구하기
  const PullCurrencyCode = (category='', key='', depth = '0', include='') => {
    const formData = { category: category, key: key, depth: depth, include: include }
    CodeApi.comCode(formData).then(response => {
      if(response.status === 200) {
        let value = response.data.list.length > 0 ? response.data.list[0].value : '';
        setCurrency(value);
        setCurrencys(response.data.list);
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    });    
    return [];
  }

  return (
    <div className="product-detail">
      <div className="div-2">
        <div className="body-frame">
          <div className="bulletin-body-title">
            <div className="title">상품 상세</div>
          </div>

          <div className="body-sub-title" />

          <div className="container">
            <div className="form">
              <div className="fieldset">
                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">브랜드:</div>
                    </div>

                    {/* <div className="data-frame">
                    <div className="text-wrapper-7">{prd.brand}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="brand" value={brand} onChange={(e) => handleBrandChange(e)} ref={brandRef}/>


                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">모델:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.model}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="model" value={model} onChange={(e) => handleModelChange(e)} ref={modelRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">카테고리:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.category}</div>
                    </div> */}
                    {/* <input className="data-frame" type="text" name="category" value={category} onChange={(e) => handleCategoryChange(e)} ref={categoryRef}/> */}
                    <select className="data-frame" id="category" name="category" value={category} onChange={handleCategoryChange} ref={categoryRef}>
                      { categoryOptions.filter((option, index, total) => index > 0).map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">상품 제목:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.product_name}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="productName" value={productName} onChange={(e) => handleProductNameChange(e)} ref={productNameRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">검색 키워드:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.keywords}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="keywords" value={keywords} onChange={(e) => handleKeywordsChange(e)} ref={keywordsRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">화폐단위:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.currency}</div>
                    </div> */}
                    {/* <input className="data-frame" type="text" name="currency" value={currency} onChange={(e) => handleCurrencyChange(e)} ref={currencyRef}/> */}
                    <select className="data-frame" id="currency" name="currency" value={currency} onChange={handleCurrencyChange} ref={currencyRef}>
                      { currencyUnitOptions.filter((option, index, total) => index > 0).map((option, index, total) => ( 
                        <option value={option.value}>{option.label}</option>
                      ))}
                    </select>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">기준 가격:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.first_price}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="firstPrice" value={firstPrice} onChange={(e) => handleFirstPriceChange(e)} ref={firstPriceRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">최종 가격:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.final_price}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="finalPrice" value={finalPrice} onChange={(e) => handleFinalPriceChange(e)} ref={finalPriceRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">정품 하한:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.price_floor}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="priceFloor" value={priceFloor} onChange={(e) => handlePriceFloorChange(e)} ref={priceFloorRef}/>

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-8">내용:</div>
                    </div>

                    {/* <div className="data-frame-2">
                      <div className="text-wrapper-9">{prd.content}</div>
                    </div> */}
                    {/* <textarea className="data-frame" type="text" name="content" value={content} onChange={(e) => handleContentChange(e)} ref={contentRef}/> */}
                    <textarea className="data-frame" name="content" value={content} ref={contentRef} style={{width:"610px", height:"300px"}} onChange={(e) => handleContentChange(e)} placeholder="조사 요청 내용을 입력해 주세요."></textarea>

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일01:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb1}</div>
                    </div>

                    <div className="preview-wrapper">
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb1})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refThumb1)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" className="thumb1" accept="image/*" ref={refThumb1} onChange={(e) => handleThumb1Change(e)} style={{ display: "none" }} />

                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일02:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb2}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb2})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refThumb2)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refThumb2} onChange={(e) => handleThumb2Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일03:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb3}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb3})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refThumb3)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refThumb3} onChange={(e) => handleThumb3Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일04:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb4}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb4})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refThumb4)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refThumb4} onChange={(e) => handleThumb4Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">썸네일05:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{thumb5}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${thumb5})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refThumb5)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refThumb5} onChange={(e) => handleThumb5Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지01:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image1}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image1})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refImage1)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refImage1} onChange={(e) => handleImage1Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지02:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image2}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image2})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refImage2)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refImage2} onChange={(e) => handleImage2Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지03:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image3}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image3})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refImage3)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refImage3} onChange={(e) => handleImage3Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지04:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image4}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image4})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refImage4)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refImage4} onChange={(e) => handleImage4Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">바디 이미지05:</div>
                    </div>

                    <div className="data-frame-3" style={{wordBreak: 'break-all'}}>
                      <div className="text-wrapper-10">{image5}</div>
                    </div>

                    <div className="preview-wrapper">
                      {/* <img className="preview" alt="Preview" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/6796017edb002663fcce6711/img/preview@2x.png"/> */}
                      <div className="preview" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}${image5})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>
                    </div>

                    <div className="data-frame-4" style={{cursor:'pointer'}} onClick={(e) => {handleFileSelectClick(e, refImage5)}}>
                      <div className="text-wrapper-11">선택</div>
                    </div>
                    <input type="file" accept="image/*" ref={refImage5} onChange={(e) => handleImage5Change(e)} style={{ display: "none" }} />
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">거래처 ID:</div>
                    </div>

                    {/* <div className="data-frame">
                      <div className="text-wrapper-7">{prd.customer_id}</div>
                    </div> */}
                    <input className="data-frame" type="text" name="customerId" value={customerId} onChange={(e) => handleCustomerIdChange(e)} ref={customerIdRef}/>

                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">활성여부:</div>
                    </div>

                    <div className="data-frame" style={{width:'100px'}}>
                      {/* <div className="text-wrapper-7">{prd.active}</div> */}
                      <select className="text-wrapper-7" id="active" name="active" value={active} onChange={handleSelectActiveChange} style={{border:"none"}}>
                        { activeOptions.map((option, index, total) => ( 
                          <option value={option.value}>{option.label}</option>
                        ))}
                      </select>


                    </div>
                  </div>
                </div>
              </div>

              <div className="field-summary">
                <div className="list-frame" style={{cursor:'pointer'}} onClick={(e)=>{handleDelete(e)}}>
                  <div className="text-wrapper-12">삭제</div>
                </div>

                <div className="list-frame" style={{cursor:'pointer'}} onClick={(e)=>{handleUpdate(e)}}>
                  <div className="text-wrapper-12">수정</div>
                </div>

                <div className="list-frame-2" style={{cursor:'pointer'}} onClick={(e)=>{handleBackward(e)}}>
                  <div className="text-wrapper-13">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LeftMenu className="left-menu-instance" />
        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
      </div>
    </div>
  );
};
