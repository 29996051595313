import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LawsuitList } from "../components/LawsuitList";
import { getLawsuitList } from "../modules/lawsuits";
import { useAuth } from "../contexts/auth/auth.context";

export const LawsuitListContainer = ({nationParam, platformParam, lawsuitLevelParam, toDateParam, searchTypeParam, searchTextParam, pageParam }) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ lawsuitLevel, setLawsuitLevel ] = useState(lawsuitLevelParam);
  const [ toDate, setToDate ] = useState(toDateParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ page, setPage ] = useState(pageParam);

  const { data, loading, error} = useSelector((state) => state.lawsuitReducer.lawsuitlist);
  const dispatch = useDispatch();

  // 국가
  const onNationSubmit = (nationParam, platformParam) => {
    setPage(1);
    setNation(nationParam);
    setPlatform(platformParam);
  }

  // 플랫폼
  const onPlatformSubmit = (platformParam) => {
    setPage(1);
    setPlatform(platformParam);
  }

  // 진행상황
  const onLawsuitLevelSubmit = (lawsuitLevelParam) => {
    setPage(1);
    setLawsuitLevel(lawsuitLevelParam);
  }

  // 날짜
  const onToDateSubmit = (toDateParam) => {
    setPage(1);
    setToDate(toDateParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(1);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }

  // 컴포넌트 마운트 후 포스트 목록 요청
  useEffect(() => {
    dispatch(getLawsuitList(nation, platform, lawsuitLevel, toDate, searchType, searchText, page));
  }, [dispatch, nation, platform, lawsuitLevel, toDate, searchType, searchText, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) 
    return <div>에러 발생!</div>;

  return (
    <LawsuitList 
      dataParam={data}
      nationParam = {nation}
      platformParam = {platform}
      lawsuitLevelParam = {lawsuitLevel}
      toDateParam = {toDate}
      searchTypeParam = {searchType}
      searchTextParam = {searchText}
      pageParam = {page >= 1 ? page : 1}
      onPageSubmit={onPageSubmit} 
      onNationSubmit={onNationSubmit} 
      onPlatformSubmit={onPlatformSubmit} 
      onLawsuitLevelSubmit={onLawsuitLevelSubmit} 
      onToDateSubmit={onToDateSubmit} 
      onSearchTextSubmit={onSearchTextSubmit}
    />
  );
}