import React from "react";
import { FooterPanel } from "../../components/FooterPanel";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import { LeftMenu } from "../../components/LeftMenu";
import "./style.css";
import { Link, useNavigate } from "react-router-dom";

export const LawsuitDetail = ({law}) => {
  const navigate = useNavigate();

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }

  return (
    <div className="lawsuit-detail">
      <div className="div-2">
        <div className="body-frame">
          <div className="bulletin-body-title">
            <div className="title">IP 소송 금융 상세</div>
          </div>

          <div className="body-sub-title" />

          <div className="container">
            <div className="form">
              <div className="fieldset">
                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">국가:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.nation}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">플랫폼:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.platform}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">진행상황:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.lawsuit_level}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">판매상명:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.seller_name}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">제품:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.product}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">공증번호:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.notarization_no}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">피고:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.defendant}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">1심법원:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.first_trial_court}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">1심사건번호:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.first_trial_case_no}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">제품판매여부:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.sold_type}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">종결여부:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.finished}</div>
                    </div>
                  </div>
                </div>

                <div className="container-wrapper">
                  <div className="container-3">
                    <div className="label-frame">
                      <div className="text-wrapper-6">비고:</div>
                    </div>

                    <div className="data-frame-2">
                      <div className="text-wrapper-8" dangerouslySetInnerHTML={{__html: law.comment}}></div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">제출일자:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.submit_at.substring(0,10)}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">입안일자:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.entried_at.substring(0,10)}</div>
                    </div>
                  </div>
                </div>

                <div className="horizontal-border">
                  <div className="container-2">
                    <div className="label-frame">
                      <div className="text-wrapper-6">공증일자:</div>
                    </div>

                    <div className="data-frame">
                      <div className="text-wrapper-7">{law.notarized_at.substring(0,10)}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field-summary">
                <div className="list-frame" style={{cursor:'pointer'}} onClick={() => {navigate(-1)}}>
                  <div className="text-wrapper-9">목록</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <LeftMenu className="left-menu-instance" />
        <RightMenu className="right-menu-instance" />
        <MainLogo className="main-logo-instance" />
      </div>
    </div>
  );
};
