import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams, useNavigationType } from "react-router-dom";
import { SellerListContainer } from "../../containers/SellerListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const SellerListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  

  // Filter Variables
  let nation = '';
  let platform = '';
  let searchType = '';
  let searchText = '';
  let page = '1';
  if (navType === 'PUSH') {
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('page', page);
  } else {
    nation = localStorage.getItem('nation');
    platform = localStorage.getItem('platform');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    page = localStorage.getItem('page');
  }

  return (
    <SellerListContainer 
      nationParam={nation} 
      platformParam={platform} 
      searchTypeParam={searchType} 
      searchTextParam={searchText} 
      pageParam={parseInt(page)} 
    />
  );
}

