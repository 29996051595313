import React, { useState, useRef, useContext, useEffect, useCallback } from "react";
import { MainLogo } from "../MainLogo";
import { LeftMenu } from "../../components/LeftMenu";
import { MessagePanel } from "../MessagePanel";
import { RightMenu } from "../RightMenu";
import "./style.css";

import { useAuth } from "../../contexts/auth/auth.context";
import { BACKEND_SERVER_BASEURL } from "../../config/constant";
import { FaRegStar, FaHeart } from 'react-icons/fa6'
import * as productApi from "../../api/products";
import ConfirmContext from '../../contexts/confirm/ConfirmContext';
import { Link, useNavigate } from "react-router-dom";
import ForgeryApi from "../../api/forgerys";
import ReportApi from "../../api/reports";
import html2canvas from "html2canvas";

import { infringeTypes, infringeTypeOptions } from "../../config/codes";
import { platforms, platformOptions } from "../../config/codes";
import { categories, categoryOptions } from "../../config/codes";
import { currencyUnits, currencyUnitOptions } from "../../config/codes";
import { reportMethods, reportMethodOptions } from "../../config/codes";
import { reportStatuses, reportStatusOptions } from "../../config/codes";

export const ReportDetail = ({prd}) => {
  const [ position, setPostion ] = useState({ header: false, mainer:false, footer:false,});
  const [ bodyBottom, setBodyBottom ] = useState(100000);
  
  const { user, setUser, global, setGlobal } = useAuth();
  const [ determinDesc, setDeterminDesc ] = useState('');
  const [ reviewed, setReviewed ] = useState(0);  
  const [ jump, setJump ] = useState('');
  const [ fixedTab, setFixedTab ] = useState(false);
  const { confirm: confirmComp } = useContext(ConfirmContext);  

  // 판별 결과 JSON
  const [dtms, setDtms] = useState(prd ? JSON.parse(prd.determins) : []);
  const [ thumbClickedUrl, setThumbClickedUrl ] = useState((dtms && dtms[0].determin_image) ? displayImage(dtms[0].determin_image) : null );

  const [ imageChecked, setImageChecked ] = useState(false);
  const [error, setError] = useState("");

  const [ reportMethod, setReportMethod] = useState(prd.method)
  const handleReportMethodChange = (event) => {
    let value = event.target.value;
    setReportMethod(value);
  };

  const [ reportStatus, setReportStatus] = useState(prd.status)
  const handleReportStatusChange = (event) => {
    let value = event.target.value;
    setReportStatus(value);
  };

  const [infringe, setInfringe] = useState(prd.infringe);
  const handleInfringeChange = (event) => {
    let value = event.target.value;
    setInfringe(value);
  };

  const [ content, setContent] = useState(prd.content)
  const handleContentChange = (event) => {
    let value = event.target.value;
    setContent(value);
  };

  const refCapture = useRef();
  const [capturedImage, setCapturedImage] = useState(null);
  const [isCapturing, setIsCapturing] = useState(false);

  const refThumbMain = useRef();
  const navigate = useNavigate();

  function image_similarity_string(image_similar_list) {
    let data = '';
    image_similar_list.map((image_similar, index, total) => {
      data += image_similar.similarity.toFixed(2) + ', ';
    });
    if (data.substring(data.length-2) === ", ") {
      data = data.substring(0, data.length-2);
    } 
    return data;
  }

  const onDelete = (_id, e) => {
    e.preventDefault();

    // 위조품 데이터 재거 서버에 전송
    let formData = new FormData();
    formData.append("id", _id);
    ForgeryApi.DeleteForgery(_id).then(response => {
      if(response.status >= 200 || response.status <= 204) {
        alert(`위조품 데이터가 삭제되었습니다.`);
        navigate(-1);
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function isNumeric(data) {
    return !isNaN(data);
  }
    
  // validate Input Columns
  const validateInputs = () => {
    if (infringe.length <= 0) {
      const result = alert("침해 사유를 선택해 주세요!");
      return false;
    }
    else if (content.length <= 0) {
      const result = alert("신고 내용을 기술해 주세요!");
      return false;
    }
    return true;
  }

  function UpdateReportData(formData) {
    ReportApi.UpdateReport(prd.id, formData).then(response => {
      if(response.status == 200 || response.status == 201) {
        alert("정상적으로 저장되었습니다.")
      } else {
        alert(response.statusText)
      }
    }).catch(error => {
      console.log(error.message);
      alert(error.message)
    })
  }

  function postDeterminedByClicked() {
    let today = new Date()
    let today_at = today.toISOString().split(".")[0];

    let formData = new FormData();
    if (reportStatus === "ready") {
      formData.append("created_at", today_at);
    }
    else if (reportStatus === "apply") {
      formData.append("reported_at", today_at);
    }
    else if (reportStatus === "confirm") {
      formData.append("confirmed_at", today_at);
    }
    formData.append("method", reportMethod);
    formData.append("status", reportStatus);
    formData.append("infringe", infringe);
    formData.append("content", content);

    let determins = []
    dtms.map( (dtm, index, total) => {
      determins.push({"product_image": dtm.product_image, "product_report": dtm.product_report, "determin_image": dtm.determin_image, "determin_report": dtm.determin_report,})
    });
    formData.append("determins", JSON.stringify(determins));

    UpdateReportData(formData);
  }

  const onPrevious = (e) => {
    navigate(-1);
  }

  const onSubmit = e => {
    e.preventDefault();
    if (validateInputs() == true) {
      postDeterminedByClicked();
    }  
  }

  const onDownload2 = useCallback(async () => {
    if (!refCapture.current) {
      console.error("Image element not found.");
      return;
    }
    setIsCapturing(true); // Set capturing state

    try {
      const canvas = await html2canvas(refCapture.current, {
        useCORS: true, // Important for images from different domains
        // Add other html2canvas options as needed (e.g., scale, logging)
      });

      const dataUrl = canvas.toDataURL('image/png');
      setCapturedImage(dataUrl);
    } catch (error) {
      console.error("Error capturing image:" + error);
    } finally {
      setIsCapturing(false); // Reset capturing state
    }    
  }, []);

  const onDownload = useCallback(async () => {
    const target = document.getElementsByClassName('section-frame-3');

    // try {
    await html2canvas(refCapture.current, {useCORS: true,}).then(canvas => {
      // document.body.appendChild(canvas)
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = canvas.toDataURL('image/png');
      link.download = 'result.png';
      link.click();
      document.body.removeChild(link);
    });
    // } catch (err) {
    //   console.log('img download fail error', err);
    // }
    // html2canvas(target).then(canvas => {
    //   document.body.appendChild(canvas)
    // });
    
    // html2canvas(refCapture.current).then((canvas) => {
    //   const link = document.createElement('a');
    //   document.body.appendChild(link);
    //   link.href = canvas.toDataURL('image/png');
    //   link.download = 'result.png';
    //   link.click();
    //   document.body.removeChild(link);
    // });

    // htmlToImage.toJpeg(target[0], { quality: 0.95 })
    // .then(function (dataUrl) {
    //   document.body.appendChild(canvas)
    //   var link = document.createElement('a');
    //   link.download = 'my-image-name.jpeg';
    //   link.href = dataUrl;
    //   link.click();
    //   document.body.removeChild(link);
    // })
    // .catch((err) => {
    //   console.log(err)
    // });

    // htmlToImage.toPng(refCapture.current, { cacheBust: true, })
    // .then(function (dataUrl) {
    //   const link = document.createElement('a');
    //   link.download = 'my-image-name.jpeg';
    //   link.href = dataUrl;
    //   link.click();
    //   document.body.removeChild(link);
    // })
    // .catch((err) => {
    //   console.log(err)
    // });

    // toPng(target, { cacheBust: true })
    // .then((dataUrl) => {
    //   const link = document.createElement('a');
    //   link.download = 'my-image-name.png';
    //   link.href = dataUrl;
    //   link.click();
    // })
    // .catch((err) => {
    //   console.log(err);
    // }); 

    // if (refCapture.current) {
    //   html2canvas(refCapture.current).then(canvas => {
    //     const link = document.createElement('a');
    //     document.body.appendChild(link);
    //     link.href = canvas.toDataURL('image/png');
    //     link.download = 'result.png';
    //     link.click();
    //     document.body.removeChild(link);
    //   });
    // }
  },[]);
  
  
  const onImageCheck = (prd_param, e) => {
    e.preventDefault();
    let desc = '';
    let date = new Date();
    let tm = date.getTime();
    prd.product_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.official_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    prd.determined_similar.map((sim, index, total) => {
      if (sim === prd_param) {
        sim.checked = (sim.checked===undefined) ? true : !sim.checked;
        if (sim.checked === true) {
          let ext1 = sim.product.substring(sim.product.lastIndexOf('.') + 1);
          sim.product_file = 'product_' + tm + '.' + ext1;
          let ext2 = sim.determin.substring(sim.determin.lastIndexOf('.') + 1);
          sim.determin_file = 'determin_' + tm + '.' + ext2;
        }
      }
      if (sim.checked) {
        let dsc = sim.src_name;
        dsc += (sim.src_desc&&sim.src_desc.length > 0) ? '/' + sim.src_desc : '/';
        dsc += '/' + sim.similarity.toFixed(2);
        desc += desc.length > 0 ? ', ' + dsc : dsc;
      }
    })
    setImageChecked(!imageChecked);
    setDeterminDesc(desc);
  }

  const handleProductReportChange = (e, dtm_param) => {
    const { value, name } = e.target;
    const nextDtms = dtms.map(dtm => {
      if (dtm === dtm_param)
        dtm.product_report = value;
      return dtm;
    });
    setDtms(nextDtms);
  };

  const handleDeterminReportChange = (e, dtm_param) => {
    const { value, name } = e.target;
    const nextDtms = dtms.map(dtm => {
      if (dtm === dtm_param)
        dtm.determin_report = value;
      return dtm;
    });
    setDtms(nextDtms);
  };

  const onThumbClick = (thumburl, e) => {
    e.preventDefault();
    setThumbClickedUrl(thumburl)
  }

  const onBackward = () => {
    navigate(-1); // 이전 페이지로 이동
  }
  
  function displayImage(imgUrl) {
    if (imgUrl.startsWith('data/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}/media/${imgUrl}`;
    } else if (imgUrl.startsWith('/media/https%3A/')) {
      imgUrl = imgUrl.replace('/media/https%3A/', 'https://')
    } else if (imgUrl.startsWith('/media/')) {
      imgUrl = `${BACKEND_SERVER_BASEURL}${imgUrl}`;
    }
    // console.log('imgUrl : ' + imgUrl);
    return imgUrl;
  }

  // 스크롤 이벤트를 통해 FooterPanel 의 포지션을 동적으로 할당
  useEffect(() => {
    window.addEventListener('scroll', getFooterPostion);
    return () =>   window.removeEventListener('scroll', getFooterPostion);
  },[position]);
  // Footer를 MainBody 하단에 동적으로 붙이기 위함
  const getFooterPostion = () => {
  	const main = document.getElementById('mainbody');
    if (main) {
      const bottom = Math.max(Math.floor(window.scrollY+main.getBoundingClientRect().top+main.getBoundingClientRect().height+100), 1300);
      setBodyBottom(bottom);
    }
  }

  return (
    <div className="report-detail">
      <div className="div-2">
        <div className="overlap">
          <div className="main-frame">
            <div className="body-title">
              <div className="title">위조품 신고</div>
            </div>

            <div className="section-frame">
              <div className="frame">
                <div className="div-image-wrapper">
                  {/* <img className="div-image" alt="Div image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-image-2@2x.png" /> */}
                  {/* <div className="div-image" style={{backgroundImage: `url(${thumbClickedUrl})`, backgroundSize:'cover' }}  ref={refThumbMain} /> */}
                  <div className="div-image" style={{backgroundImage: `url(${displayImage(thumbClickedUrl)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center' }} ref={refThumbMain} />

                </div>

                <div className="frame-2">
                  {/* <div className="frame-3" />
                  <div className="frame-4" />
                  <div className="frame-5" />
                  <div className="frame-6" />
                  <div className="frame-7" /> */}
                { 
                  dtms.map((dtm, index, total) => (
                    dtm && dtm.determin_image.length > 0 ?
                    <div className={`frame-${index+3}`} style={{cursor:'pointer', border:'1px solid', backgroundImage: `url(${displayImage(dtm.determin_image)})`, backgroundSize:'contain', borderColor:'#dddddd', borderWidth: 2,borderStyle: 'solid'}} onClick={(e)=>{onThumbClick(dtm.determin_image, e)}} key={index} />
                    : null
                  ))
                }


                </div>
              </div>

              <div className="frame-8">
                <div className="frame-9">
                  <div className="text-wrapper-10">국가 : {prd.nation}</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-10">플랫폼 : {platforms[prd.platform]}</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">카테고리 : {categories[prd.category]}</div>
                </div>

                <div className="frame-10">
                  <p className="p">
                  {prd.determin_name ? prd.determin_name.substring(0,100) : null}
                  </p>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">정상품 판매가 : {prd.product_price.toLocaleString('ko-KR')}({prd.currency})</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">정상품 하한가 : {(prd.price_floor*(prd.krw_exrate === 0.0 ? 1.0 : prd.krw_exrate)).toLocaleString('ko-KR')}</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">위조품 판매가 : {(prd.determin_price*(prd.krw_exrate === 0.0 ? 1.0 : prd.krw_exrate)).toLocaleString('ko-KR')}</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">침해유형 : {infringeTypes[prd.infringe]}</div>
                </div>

                <div className="frame-9">
                  <a className="text-wrapper-11" href={prd.seller_home} target="blank" style={{"color": "blue"}}>판매자 : {prd.seller} 홈</a>

                </div>

                <div className="frame-9">
                  <a className="text-wrapper-11" href={prd.url} target="blank" style={{"color": "blue"}}>위조품 상세보기</a>

                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">신고일자 : {prd.reported_at ? prd.reported_at.substring(0,19) : null}</div>
                </div>

                <div className="frame-9">
                  <div className="text-wrapper-11">확정일자 : {prd.confirmed_at ? prd.confirmed_at.substring(0,19) : null}</div>
                </div>
              </div>
            </div>

            <div className="section-frame-2">
              <div className="frame-11">
                <div className="text-wrapper-12">타이틀유사도</div>
              </div>

              <div className="frame-12">
                <div className="frame-13">
                  <div className="text-wrapper-13">정품 제목</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">{prd.product_name}</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">위조품 제목</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">{prd.determin_name.substring(0,80)}</div>
                </div>
              </div>

              <div className="frame-12">
                <div className="frame-15">
                  <div className="text-wrapper-13">브랜드/모델</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">{prd.brand} / {prd.model}</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">타이틀 유사도</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">{prd ? parseFloat(prd.similarity).toFixed(2) : null}</div>
                </div>
              </div>
            </div>

            <div className="section-frame-2">
              <div className="frame-17">
                <div className="text-wrapper-12">가격 유사도({prd.currency ? currencyUnits[prd.currency] : null})</div>
              </div>

              <div className="frame-12">
                <div className="frame-13">
                  <div className="text-wrapper-13">정품 기준가</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">{prd.product_price ? prd.product_price.toLocaleString('ko-KR') : null}</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">위조품 가격</div>
                </div>

                <div className="frame-14">
                  <div className="text-wrapper-13">{prd.determin_price ? prd.determin_price.toLocaleString('ko-KR') : null}</div>
                </div>
              </div>

              <div className="frame-12">
                <div className="frame-15">
                  <div className="text-wrapper-13">정품 하한가</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">{prd.price_floor ? prd.price_floor.toLocaleString('ko-KR') : null}</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">정품 하한가 기준</div>
                </div>

                <div className="frame-16">
                  <div className="text-wrapper-13">{(prd.determin_price - prd.price_floor).toLocaleString('ko-KR')}</div>
                </div>
              </div>
            </div>

            <div className="frame-wrapper">
              <div className="frame-9">
                <div className="text-wrapper-12">이미지 유사도</div>
              </div>
            </div>

            <div className="section-frame-3" ref={refCapture}>

            { dtms && dtms.map((dtm, index, total) => ( 
              
              <div className="rec-item-frame">
                <div className="div-3">
                  <div className="thumbnail">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-img@2x.png" /> */}
                        {/* <img className="back-image" src={`${BACKEND_SERVER_BASEURL}/media/${dtm.product_image}`} /> */}
                        <div className="back-image" style={{backgroundImage: `url(${displayImage(dtm.product_image)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}} />

                      </div>

                      {/* <button className="button-box-wrapper">
                        <div className="button-box"></div>
                      </button> */}
                    </div>
                  </div>

                  <div className="report-frame">
                    {/* <div className="text-wrapper-14">11</div> */}
                    <textarea className="text-wrapper-14" name="product_report" value={dtm.product_report} style={{border:"none"}} onChange={(e) => handleProductReportChange(e, dtm)}></textarea>

                  </div>
                </div>

                <div className="div-3">
                  <div className="thumbnail">
                    <div className="overlap-group">
                      <div className="back-image-frame">
                        {/* <img className="back-image" alt="Back image" src="https://cdn.animaapp.com/projects/6614ef6f5f16da1152470b09/releases/665935372ad594dd39825363/img/div-img@2x.png" /> */}
                        {/* <img className="back-image" style={{backgroundImage: `url(${BACKEND_SERVER_BASEURL}/media/${dtm.determin_image})`, backgroundSize:'contain'}} /> */}
                        <div className="back-image" style={{backgroundImage: `url(${displayImage(dtm.determin_image)})`, backgroundSize:'contain', backgroundRepeat:'no-repeat', backgroundPosition:'center'}}/>

                      </div>

                      {/* <button className="button-box-wrapper">
                        <div className="button-box"></div>
                      </button> */}
                    </div>
                  </div>

                  <div className="report-frame-2">
                    {/* <div className="text-wrapper-14">11</div> */}
                    <textarea className="text-wrapper-14" name="determin_report" value={dtm.determin_report} style={{border:"none"}} onChange={(e) => handleDeterminReportChange(e, dtm)}></textarea>

                  </div>
                </div>
              </div>

            ))}
              
            </div>

            <div className="section-frame-4">
              <div className="form-title-frame">
                <div className="form-title">신고의견</div>
              </div>

              <div className="form-caption-frame">
                <div className="form-caption-wrapper">
                  <div className="form-caption">신고 의견</div>
                </div>

                <div className="form-determ-tag-wrapper">
                  <div className="text-wrapper-15">신고 방법</div>
                </div>

                <div className="form-determ-drop-wrapper">
                  {/* <div className="text-wrapper-15">인터넷</div> */}
                  <select className="text-wrapper-15" id="reportMethod" name="reportMethod" value={reportMethod} onChange={handleReportMethodChange} style={{border:"none"}}>
                    { reportMethodOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                </div>

                <div className="form-determ-tag-wrapper">
                  <div className="text-wrapper-15">신고 결과</div>
                </div>

                <div className="form-determ-drop-wrapper">
                  {/* <div className="text-wrapper-15">신고 확정</div> */}
                  <select className="text-wrapper-15" id="reportStatus" name="reportStatus" value={reportStatus} onChange={handleReportStatusChange} style={{border:"none"}}>
                    { reportStatusOptions.map((option, index, total) => ( 
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>


                </div>
              </div>

              <div className="form-text-frame">
                {/* <div className="form-text">내용을 입력해 주세요</div> */}
                <textarea className="form-text" name="content" value={content} style={{border:"none"}} onChange={handleContentChange}></textarea>

              </div>

              <div className="form-submit-frame">
                <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={(e)=>{onPrevious(e)}}>
                  <div className="form-submit">목&nbsp;&nbsp;록</div>
                </div>

                <div className="form-submit-layout" style={{cursor:'pointer'}} onClick={onDownload}>
                  <div className="form-submit">다운로드</div>
                </div>

                <div className="form-submit-wrapper" style={{cursor:'pointer'}} onClick={onSubmit}>
                  <div className="form-submit-2">신&nbsp;&nbsp;고</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="left-menu-instance" />
      </div>
    </div>
  );
};
