import React from "react";
import { LeftMenu } from "../../components/LeftMenu";
import { MainLogo } from "../../components/MainLogo";
import { RightMenu } from "../../components/RightMenu";
import "./style.css";

export const MerchantDetail = () => {
  return (
    <div className="merchant-detail">
      <div className="div-2">
        <div className="main-frame">
          <div className="bulletin-body-title">
            <div className="title">위조품 판별 상세</div>
          </div>

          <div className="body-sub-title" />

          <div className="section-frame">
            <div className="frame">
              <div className="div-image-wrapper">
                <img
                  className="div-image"
                  alt="Div image"
                  src="/img/div-image-2-2-1.png"
                />
              </div>

              <div className="frame-2">
                <div className="frame-3" />

                <div className="frame-4" />

                <div className="frame-5" />

                <div className="frame-6" />

                <div className="frame-7" />
              </div>
            </div>

            <div className="frame-8">
              <div className="frame-9">
                <div className="text-wrapper-7">쇼핑몰 카테고리</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">브랜드 몰</div>
              </div>

              <div className="frame-10">
                <p className="p">
                  판별품 이름판별품 이름판별품 이름판별품 이름판별품 이름판별품
                  이름판별품 이름판별품 이름판별품 이름판별품 이름판별품
                  이름판별품 이름판별품 이름판별품 이름판별품 이름판별품 이름
                </p>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">가격</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">판매자 홈</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">유알엘</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">수집형태</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">이미지유사도</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">공홈유사도</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">판별품유사도</div>
              </div>

              <div className="frame-9">
                <div className="text-wrapper-7">:</div>
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-11">
              <div className="text-wrapper-8">타이틀유사도</div>
            </div>

            <div className="frame-12">
              <div className="frame-13">
                <div className="text-wrapper-9">정품 제목</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">상품명</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">위조품 제목</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">상품명</div>
              </div>
            </div>

            <div className="frame-12">
              <div className="frame-15">
                <div className="text-wrapper-9">브랜드/모델</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">상품명</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">타이틀 유사도</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">상품명</div>
              </div>
            </div>
          </div>

          <div className="section-frame-2">
            <div className="frame-17">
              <div className="text-wrapper-8">가격 유사도</div>
            </div>

            <div className="frame-12">
              <div className="frame-13">
                <div className="text-wrapper-9">정품 기준가</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">100</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">위조품 가격</div>
              </div>

              <div className="frame-14">
                <div className="text-wrapper-9">100</div>
              </div>
            </div>

            <div className="frame-12">
              <div className="frame-15">
                <div className="text-wrapper-9">정품 하한가</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">0.802145</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">정품 하한가 기준</div>
              </div>

              <div className="frame-16">
                <div className="text-wrapper-9">0.802145</div>
              </div>
            </div>
          </div>

          <div className="frame-wrapper">
            <div className="frame-9">
              <div className="text-wrapper-8">정품 이미지 유사도</div>
            </div>
          </div>

          <div className="section-frame-3">
            <div className="rec-item-frame">
              <div className="div-3">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img
                        className="back-image"
                        alt="Back image"
                        src="/img/backimage.png"
                      />
                    </div>

                    <button className="button-box-wrapper">
                      <div className="button-box"></div>
                    </button>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-18">
                    <div className="frame-15">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="frame-15">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-19" />
                </div>
              </div>

              <div className="div-3">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img
                        className="back-image"
                        alt="Back image"
                        src="/img/image.png"
                      />
                    </div>

                    <button className="button-box-wrapper">
                      <div className="button-box"></div>
                    </button>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-18">
                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-19" />
                </div>
              </div>
            </div>

            <div className="rec-item-frame">
              <div className="div-3">
                <div className="thumbnail">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img
                        className="back-image"
                        alt="Back image"
                        src="/img/backimage.png"
                      />
                    </div>

                    <button className="button-box-wrapper">
                      <div className="button-box"></div>
                    </button>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-18">
                    <div className="frame-15">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="frame-15">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-19" />
                </div>
              </div>

              <div className="div-3">
                <div className="overlap-group-wrapper">
                  <div className="overlap-group">
                    <div className="back-image-frame">
                      <img
                        className="back-image"
                        alt="Back image"
                        src="/img/backimage.png"
                      />
                    </div>

                    <button className="button-box-wrapper">
                      <div className="button-box"></div>
                    </button>
                  </div>
                </div>

                <div className="info-frame">
                  <div className="frame-18">
                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-18">
                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>

                    <div className="frame-16">
                      <div className="text-wrapper-9">11</div>
                    </div>
                  </div>

                  <div className="frame-19" />
                </div>
              </div>
            </div>
          </div>

          <div className="section-frame-4">
            <div className="form-title-frame">
              <div className="form-title">판별하기</div>
            </div>

            <div className="form-caption-frame">
              <div className="form-caption-wrapper">
                <div className="form-caption">판별 내용</div>
              </div>

              <div className="form-determ-tag-wrapper">
                <div className="form-determ-tag">침해 사유</div>
              </div>

              <div className="form-determ-drop-wrapper">
                <div className="form-determ-drop">판별 사유</div>
              </div>
            </div>

            <div className="form-text-frame">
              <div className="form-text-wrapper">
                <div className="form-text">내용을 입력해 주세요</div>
              </div>
            </div>

            <div className="form-submit-frame">
              <div className="form-submit-layout">
                <div className="form-submit">삭&nbsp;&nbsp;제</div>
              </div>

              <div className="form-submit-wrapper">
                <div className="form-submit-2">저&nbsp;&nbsp;장</div>
              </div>
            </div>
          </div>
        </div>

        <MainLogo className="main-logo-instance" />
        <RightMenu className="right-menu-instance" />
        <LeftMenu className="left-menu-instance" />
      </div>
    </div>
  );
};
