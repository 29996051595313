import { useParams } from "react-router";
import { MerchantDetailContainer } from "../../containers/MerchantDetailContainer";
import { MerchantListContainer } from "../../containers/MerchantListContainer";

export const MerchantDetailPage = () => {
  const { _id } = useParams();  

  return (
    <MerchantDetailContainer _id={_id} />
  )
}
