import React, { useEffect } from "react";
import { Link, useNavigate, useSearchParams, useNavigationType } from "react-router-dom";
import { ForgeryListContainer } from "../../containers/ForgeryListContainer";
import { useAuth } from "../../contexts/auth/auth.context";

export const ForgeryListPage = () => {
  const { user, setUser, global, setGlobal } = useAuth();
  const navType = useNavigationType();  

  // Filter Variables
  let brand = '';
  let nation = '';
  let platform = '';
  let infringe = '';
  let searchType = '';
  let searchText = '';
  let sort = 'price_low';
  let page = '1';
  if (navType === 'PUSH') {
    localStorage.setItem('brand', brand);
    localStorage.setItem('nation', nation);
    localStorage.setItem('platform', platform);
    localStorage.setItem('infringe', infringe);
    localStorage.setItem('searchType', searchType);
    localStorage.setItem('searchText', searchText);
    localStorage.setItem('sort', sort);
    localStorage.setItem('page', page);
  } else {
    brand = localStorage.getItem('brand');
    nation = localStorage.getItem('nation');
    platform = localStorage.getItem('platform');
    infringe = localStorage.getItem('infringe');
    searchType = localStorage.getItem('searchType');
    searchText = localStorage.getItem('searchText');
    sort = localStorage.getItem('sort');
    page = localStorage.getItem('page');
  }

  return (
    <ForgeryListContainer 
      brandParam={brand} 
      nationParam={nation} 
      platformParam={platform} 
      infringeParam={infringe} 
      searchTypeParam={searchType} 
      searchTextParam={searchText} 
      sortParam={sort} 
      pageParam={parseInt(page)} 
  />
  );
}

