import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CollectList } from "../components/CollectList";
import { getCollectList } from "../modules/collects";
import { useAuth } from "../contexts/auth/auth.context";

export const CollectListContainer = ({brandParam, nationParam, platformParam, searchTypeParam, searchTextParam, pageParam}) => {
  const { user, setUser, global, setGlobal } = useAuth();
  const [ brand, setBrand ] = useState(brandParam);
  const [ nation, setNation ] = useState(nationParam);
  const [ platform, setPlatform ] = useState(platformParam);
  const [ searchType, setSearchType ] = useState(searchTypeParam);
  const [ searchText, setSearchText ] = useState(searchTextParam);
  const [ page, setPage ] = useState(pageParam);
  const BODY_COUNT_PER_PAGE = 24;

  const { data, loading, error} = useSelector((state) => state.collectReducer.collectlist);
  const dispatch = useDispatch();

  // 브랜드
  const onBrandSubmit = (brandParam) => {
    setPage(0);
    setBrand(brandParam);
  }

  // 국가
  const onNationSubmit = (nationParam, platformParam) => {
    setPage(0);
    setNation(nationParam);
    setPlatform(platformParam);
  }

  // 플랫폼
  const onPlatformSubmit = (platformParam) => {
    setPage(0);
    setPlatform(platformParam);
  }

  // 검색어
  const onSearchTextSubmit = (searchTypeParam, searchTextParam) => {
    setPage(0);
    setSearchType(searchTypeParam);
    setSearchText(searchTextParam);
  }

  // 페이지
  const onPageSubmit = (pageParam) => {
    setPage(pageParam);
  }


  // 컴포넌트 마운트 후 목록 요청
  //       { script: { script: doc['determin_price'].value < doc['price_floor'].value}}      
  useEffect(() => {
    let params = { 
      track_total_hits: true,
      size: 24, 
      sort: [ 
        { created_at: { order: "desc", format: "strict_date_optional_time", unmapped_type: "boolean" } }, 
        { "_doc": { "order": "desc", "unmapped_type": "boolean" } } 
      ],      
      query : { 
        bool: { 
          filter: 
          [ 
            // { script: { script: "doc['determin_price'].value < doc['price_floor'].value"} }
          ]
        }
      }
    };
    if (brand.length > 0) {
      params.query.bool.filter.push({ match: { brand: `${brand}` }})
    }
    if (nation.length > 0) {
      params.query.bool.filter.push({ match: { nation: `${nation}` }})
    }
    if (platform.length > 0) {
      params.query.bool.filter.push({ match: { mall: `${platform}` }})
    }
    if (searchType === "seller" && searchText.length > 0) {
        params.query.bool.filter.push({ match: { seller: `${searchText}` }})
    }
    else if (searchType === "collect_name" && searchText.length > 0) {
      params.query.bool.filter.push({ match: { collect_name: `${searchText}` }})
    }
    else if (searchText.length > 0) {
      params.query.bool.filter.push({ multi_match: { fields: ["seller", "collect_name"], query: `${searchText}` }})
    }
    params["from"] = page < 0 ? 0 : page * BODY_COUNT_PER_PAGE;

    // console.log(JSON.stringify(params));
    dispatch(getCollectList(params));
    if (page < 0) setPage(0);
  }, [dispatch, brand, nation, platform, searchType, searchText, page]);

  if ((loading && !data)) return <div>로딩중...</div>;
  if (error) return <div>에러 발생!</div>;
  return (
    <CollectList 
    dataParam={data}
    brandParam = {brand}
    nationParam = {nation}
    platformParam = {platform}
    searchTypeParam = {searchType}
    searchTextParam = {searchText}
    pageParam = {page}
    onPageSubmit={onPageSubmit} 
    onBrandSubmit={onBrandSubmit} 
    onNationSubmit={onNationSubmit} 
    onPlatformSubmit={onPlatformSubmit} 
    onSearchTextSubmit={onSearchTextSubmit}
    />
  );
}

